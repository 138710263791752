"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtpInput = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const react_native_1 = require("react-native");
const OtpInput_styles_1 = require("./OtpInput.styles");
const VerticalStick_1 = require("./VerticalStick");
const useOtpInput_1 = require("./useOtpInput");
exports.OtpInput = (0, react_1.forwardRef)((props, ref) => {
    const { models: { text, inputRef, focusedInputIndex, isFocused, placeholder }, actions: { clear, handlePress, handleTextChange, focus, handleFocus, handleBlur }, forms: { setTextWithRef }, } = (0, useOtpInput_1.useOtpInput)(props);
    const { disabled, numberOfDigits = 6, autoFocus = true, hideStick, focusColor = "#A4D0A4", focusStickBlinkingDuration, secureTextEntry = false, theme = {}, textInputProps, type = "numeric", } = props;
    const { containerStyle, inputsContainerStyle, pinCodeContainerStyle, pinCodeTextStyle, focusStickStyle, focusedPinCodeContainerStyle, filledPinCodeContainerStyle, disabledPinCodeContainerStyle, placeholderTextStyle, } = theme;
    (0, react_1.useImperativeHandle)(ref, () => ({ clear, focus, setValue: setTextWithRef }));
    const generatePinCodeContainerStyle = (isFocusedContainer, char) => {
        const stylesArray = [OtpInput_styles_1.styles.codeContainer, pinCodeContainerStyle];
        if (focusColor && isFocusedContainer) {
            stylesArray.push({ borderColor: focusColor });
        }
        if (focusedPinCodeContainerStyle && isFocusedContainer) {
            stylesArray.push(focusedPinCodeContainerStyle);
        }
        if (filledPinCodeContainerStyle && Boolean(char)) {
            stylesArray.push(filledPinCodeContainerStyle);
        }
        if (disabledPinCodeContainerStyle && disabled) {
            stylesArray.push(disabledPinCodeContainerStyle);
        }
        return stylesArray;
    };
    const placeholderStyle = {
        opacity: !!placeholder ? 0.5 : pinCodeTextStyle?.opacity || 1,
        ...(!!placeholder ? placeholderTextStyle : []),
    };
    return (React.createElement(react_native_1.View, { style: [OtpInput_styles_1.styles.container, containerStyle, inputsContainerStyle] },
        Array(numberOfDigits)
            .fill(0)
            .map((_, index) => {
            const isPlaceholderCell = !!placeholder && !text?.[index];
            const char = isPlaceholderCell ? placeholder?.[index] || " " : text[index];
            const isFocusedInput = index === focusedInputIndex && !disabled && Boolean(isFocused);
            const isFilledLastInput = text.length === numberOfDigits && index === text.length - 1;
            const isFocusedContainer = isFocusedInput || (isFilledLastInput && Boolean(isFocused));
            return (React.createElement(react_native_1.Pressable, { key: `${char}-${index}`, disabled: disabled, onPress: handlePress, style: generatePinCodeContainerStyle(isFocusedContainer, char), testID: "otp-input" }, isFocusedInput && !hideStick ? (React.createElement(VerticalStick_1.VerticalStick, { focusColor: focusColor, style: focusStickStyle, focusStickBlinkingDuration: focusStickBlinkingDuration })) : (React.createElement(react_native_1.Text, { style: [
                    OtpInput_styles_1.styles.codeText,
                    pinCodeTextStyle,
                    isPlaceholderCell ? placeholderStyle : {},
                ] }, char && secureTextEntry ? "•" : char))));
        }),
        React.createElement(react_native_1.TextInput, { value: text, onChangeText: handleTextChange, maxLength: numberOfDigits, inputMode: type === "numeric" ? type : "text", textContentType: "oneTimeCode", ref: inputRef, autoFocus: autoFocus, secureTextEntry: secureTextEntry, autoComplete: react_native_1.Platform.OS === "android" ? "sms-otp" : "one-time-code", "aria-disabled": disabled, editable: !disabled, testID: "otp-input-hidden", onFocus: handleFocus, onBlur: handleBlur, caretHidden: react_native_1.Platform.OS === "ios", ...textInputProps, style: [OtpInput_styles_1.styles.hiddenInput, textInputProps?.style] })));
});
