"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerticalStick = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const react_native_1 = require("react-native");
const OtpInput_styles_1 = require("./OtpInput.styles");
exports.VerticalStick = (0, react_1.memo)(({ focusColor, style, focusStickBlinkingDuration = 350 }) => {
    const opacityAnim = (0, react_1.useRef)(new react_native_1.Animated.Value(1)).current;
    (0, react_1.useEffect)(() => {
        react_native_1.Animated.loop(react_native_1.Animated.sequence([
            react_native_1.Animated.timing(opacityAnim, {
                toValue: 0,
                useNativeDriver: true,
                duration: focusStickBlinkingDuration,
            }),
            react_native_1.Animated.timing(opacityAnim, {
                toValue: 1,
                useNativeDriver: true,
                duration: focusStickBlinkingDuration,
            }),
        ]), {
            iterations: -1,
        }).start();
    }, []);
    return (React.createElement(react_native_1.Animated.View, { style: { opacity: opacityAnim } },
        React.createElement(react_native_1.View, { style: [OtpInput_styles_1.styles.stick, focusColor ? { backgroundColor: focusColor } : {}, style], testID: "otp-input-stick" })));
});
